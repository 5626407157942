<template>
  <div id="ResultDetailComponent">
    <div class="mt-2">
      <b>KRA</b>
      <el-input
        disabled
        class="mt-2"
        placeholder="Please input"
        :value="dataDetail?.name"
      ></el-input>
    </div>

    <div class=" mt-4">
      <b>Thành phẩm</b><br />
        <div 
        style="background-color: rgb(245 247 250)"  
        class=" p-2 mt-2 border rounded input-result"
        v-html="dataDetail.results ? dataDetail.results : 'Chưa nhập'"></div>
    </div>

     <div class="mt-4">
      <div class="row d-flex">
        <div class="col-6 week-result">
          <b>Kết quả đánh giá theo tháng</b>
          <el-input
            disabled
            class="mt-2"
            placeholder="Please input"
            :value="dataDetail.percent_results >= 0 &&  dataDetail.percent_results !== null ? dataDetail?.percent_results+ '%' : 'Chưa có'"
          ></el-input>
        </div>
        
      <div class="col-6">
            <b>Nhận xét</b>
              <el-input  
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 4}"
              maxlength="200"
              disabled class="mt-2"
              :value="dataDetail?.description ? dataDetail?.description : 'Không có nhận xét'"></el-input>
          </div>
        </div>
      </div> 

      <div class="mt-4 mb-4">
        <b>Kết quả đánh giá theo tuần</b>
        <div class="row d-flex mt-2">
          <div v-for="(value, i) in dataDetail.kra_manager_weeks" class="col-6 mt-3  week-result">
            <span for="">{{ 'Tuần '+ value.week + ':' }}</span>
            <el-input disabled class="mt-2" :value="value.percent_results >= 0 && value.percent_results !== null ? value.percent_results+'%' : 'Chưa có'"></el-input>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResultKraComponent from "../component/ResultKraComponent.vue";
export default {
  components: {
    ResultKraComponent,
  },
  props: {
    dataDetail: {
      type: Object,
      default: {},
    },
  },

  data() {
    return {};
  },

  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style>
#ResultDetailComponent .el-input.is-disabled .el-input__inner,
#ResultDetailComponent .el-textarea.is-disabled .el-textarea__inner {
  color: rgba(0, 0, 0, 1);
}
.styled-link{
  color: blue !important;
}

.styled-link:hover {
  text-decoration: underline !important;
  cursor: pointer;
}
</style>
