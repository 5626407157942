<template>
  <div class="mb-2">
    <div class="mb-4">
      <b style="color: rgba(69, 70, 116, 1);">KRA</b>
      <div class="w-100 mt-1">
        <el-input type="text" :value="data.name"></el-input>
      </div>
    </div>
    <div>
      <div>
        <div>
          <b style="color: rgba(69, 70, 116, 1);"
            >Nhập thành phẩm KRA</b>
        </div>
        <div style="word-break: break-word !important">
          <el-input
            class="mt-1"
            type="textarea"
            placeholder="Nhập thành phẩm công việc"
            v-model="data.results"
            :autosize="{ minRows: 2, maxRows: 4 }"
            maxlength="200"
            show-word-limit
          >
          </el-input>
        </div>
        <div class="mt-4">
            <span class="text-danger">Lưu ý: Nhân sự cần mở quyền truy cập bảng thành phẩm cho teamlead và email hanhchinh@edutalkvn.com.</span>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer mt-3 d-flex justify-content-end">
      <el-button
        size="small"
        class="rounded"
        style="background-color: rgba(202, 202, 202, 1)"
        @click="close"
        >Huỷ</el-button
      >
      <el-button
        size="small"
        class="rounded"
        style="background-color: rgba(93, 4, 176, 1)"
        type="primary"
        @click="update"
        :disabled='loadingUpdate'
        ><i v-bind:class="[loadingUpdate ? 'el-icon-loading' : '']" class="mr-1 text-white"></i>
        Xác nhận</el-button
      >
    </div>
  </div>
</template>

<script>
import ResultKraComponent from "../component/ResultKraComponent.vue";
import { mapGetters } from "vuex";
import { UPDATE_DATA_KPI_PERSIONAL } from "../../../../../core/services/store/kpi/kpi.module";
export default {
  components: {
    ResultKraComponent,
  },
  props: {
    data: {
        type: Array,
        default: []
    },
    month: {
      type: String,
      default: ''
    },
    clearResult: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loadingUpdate: false,
    };
  },

  computed: {
    ...mapGetters(["currentUser"]),
  },
  
  methods: {
    update() {  
      if(!this.data.results) {
        this.$notify.warning({  
          title: 'Thất bại',  
          message: 'Vui lòng nhập thành phẩm!',  
          type: 'warning'  
        });
        return;  
      }
      this.loadingUpdate = true;  
      this.$store.dispatch(UPDATE_DATA_KPI_PERSIONAL, {  
        kra_id: this.data.id,  
        result: this.data.results  
      })  
      .then((res) => {  
        this.$notify.success({  
          title: 'Thành công',  
          message: 'Nhập thành phẩm thành công!',  
          type: 'success'  
        });  
        this.close(true);  
      })
      .catch((error) => {  
        let errorMessage = 'Nhập thành phẩm thất bại!';  
        if (error.response && error.response.data && error.response.data.message) {  
          errorMessage = error.response.data.message;  
        } else if (error.message) {  
          errorMessage = error.message;
        }  
        this.$notify.error({  
          title: 'Thất bại',  
          message: errorMessage,  
          type: 'error'  
        });  
      })  
      .finally(() => {  
        this.loadingUpdate = false;
      });  
    },
    
    close(is_reload) {
      this.loadingUpdate = false;
      this.$emit('close', is_reload);
    },
  },
};
</script>
