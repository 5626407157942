<template>
  <div class="row">
    <div class="w-100">
      <div id="panel-1" class="panel mt-1 m-2">
        <div class="btn-add-application d-flex justify-content-start mr-3">
          <div class="m-4" style="outline: rgba(108, 108, 108, 1)">
            <label for=""><b>Thời gian</b></label
            ><br />
            <el-date-picker
              :clearable="false"
              @change="getListMonthlyResult()"
              class="btn-outline-secondary rounded w-100"
              v-model="month"
              size="small"
              type="month"
              value-format="yyyy-MM"
              placeholder="Chọn tháng"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="panel-container show ml-4">
          <template>
            <el-tabs v-model="activeName">
              <el-tab-pane v-loading="loading" label="Điểm KPI" name="kpi">
                <KpiJobStaffComponent @emit="emitKpiStaffjob" :pageCount="last_page" :month="month" :data="dataPersonal"> </KpiJobStaffComponent>
              </el-tab-pane>
              
              <el-tab-pane v-loading="loading" label="Điểm họp tuần" name="meeting">
                <MettingJobStaffComponent :data="dataPersonal"> </MettingJobStaffComponent>
              </el-tab-pane>
            </el-tabs>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KpiJobStaffComponent from "../Staff/component/KpiJobStaffComponent.vue";
import MettingJobStaffComponent from "../Staff/component/MettingJobStaffComponent.vue";
import { SHOW_DATA_KPI_PERSIONAL , GET_KRA_METTING } from "../../../../core/services/store/kpi/kpi.module";
import { mapGetters } from "vuex";
export default {
  components: {
    KpiJobStaffComponent,
    MettingJobStaffComponent,
  },
  data() {
    return {
      page: 1,
      last_page: 1,
      activeName: "kpi",
      month: "",
      loading: false,
      dataPersonal: [],
      dataMetiing: [],
    };
  },

  computed: {
    ...mapGetters(["currentUser"]),
  },
  mounted() {
    this.getMonthCurent();
    this.getKraAndMeeting();
    this.getListMonthlyResult();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "KPI Công việc", icon: "fas fa-list" },
    ]);
  },

  watch: {},

  methods: {
    getMonthCurent() {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      this.month = `${year}-${month}`;
    },

    getListMonthlyResult() {
      const user_id = this.currentUser.id;
        if(!user_id) {
          this.$message({
          type: 'error',
          message: 'Không tìm thấy user',
          showClose: true
        })
          return;
        }
      this.$store.dispatch(SHOW_DATA_KPI_PERSIONAL, {
        page: this.page,
        user_id,
        month: this.month,
        }).then((res) => {
          this.dataPersonal = res.data;
          this.last_page =  res.data.details.last_page;
        }).catch(() => {
          
        })
    },

    getKraAndMeeting() {
      this.$store.dispatch(GET_KRA_METTING, {
        }).then((res) => {
          this.dataMetiing =  res.data;
        }).catch(() => {
          
        })
    },

    emitKpiStaffjob(obj) {
      if(obj) {
        this.page = obj;
      }
      this.getListMonthlyResult();
    }
  },
};
</script>

<style></style>
