<template>
    <div class="row" id="KpiJobStaffComponent">
      <div class="w-100">
        <div id="" class="panel-container show ml-2">
            <div class="m-2">
                <div class="ml-3">
                    <b style="color: rgba(18, 0, 219, 1);">Điểm KPI tổng: {{ data?.total_percent_kra > 0 && data?.total_percent_kra !== null ? data?.total_percent_kra + '%' : 'Chưa có' }}</b>
                </div>
                <div>
                    <div class="card-body">
                        <div class="example mb-10">
                          <div class="row filter_form">
                          </div>
                          <div class="example-preview table-responsive">
                            <table class="table table-vertical-center table-bordered" v-loading="loading" element-loading-text="Loading...">
                              <thead>
                                <tr style="background-color: #F8F8F8;">
                                  <th>KRAs</th>
                                  <th>Yêu cầu thành phẩm</th>
                                  <th style="width:8%">Trọng số</th>
                                  <th>Trạng thái</th>
                                  <th >Kết quả KRA</th>
                                  <th style="width:6%" class="text-center">Hành động</th>
                                </tr>
                              </thead>
                              <tbody v-if="data?.details?.data">
                                <tr v-for="(item, index) in data?.details?.data" :key="item.id">
                                  <td>{{ item.name ?? '' }}</td>
                                     <td>{{ item.request_result ?? '' }}</td>
                                     <td>{{ item.percent_score+'%' ?? '' }}</td>
                                     <td style="text-align:-webkit-center; width:6%">
                                      <div class="text-white rounded p-1 text-center" style="width:100px" :style="statusKpiCss[item.status]"> {{ statusKpi[item.status] }}</div>
                                    </td>
                                     <td>
                                      {{ item.percent_results >= 0 && item.percent_results !== null ? item.percent_results + '%' : 'Chưa có' }}</td>
                                     <td>
                                      <div class="d-flex justify-content-center">
                                        <button title="Xem kết quả" v-if="checkShowButton(item)" @click="dialogView(item)" type="button" class="btn btn-outline-primary p-0 mr-1"> <i class="el-icon-view p-2"></i></button>
                                        <button title="Nhập thành phẩm" v-if="item.status_leader !== 2" @click="dialogEdit(item)" type="button" class="btn btn-outline-warning p-0 ml-1"> <i class="el-icon-edit p-2"></i></button>
                                      </div>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody v-else>
                                <tr class="text-center">
                                  <td colspan="7">
                                    Không có dữ liệu
                                  </td>
                                </tr>
                                </tbody>
                            </table>
                          </div>
                          <div class="position-absolute mt-3" style="right: 40px;"> Tổng số bản ghi: <b style="color: rgb(54, 153, 255);">{{
                            data?.details?.total ? data?.details?.total : 0 }}</b></div>
                        </div>
                    </div>
                        <div class="edu-paginate mx-auto d-flex justify-content-center">
                            <paginate v-model="page" :page-count="pageCount" :page-range="3" :margin-pages="1" :click-handler="clickCallback"
                            :prev-text="'Trước'" :next-text="'Sau'" :container-class="'pagination b-pagination'" :pageLinkClass="'page-link'"
                            :next-link-class="'next-link-item'" :prev-link-class="'prev-link-item'" :prev-class="'page-link'"
                            :next-class="'page-link'" :page-class="'page-item'">
                            </paginate>
                    </div>
                </div>
                <el-dialog title="Thành phẩm KRA" :visible.sync="showDialogEdit">
                  <ResultKraComponent @close="closeDialog" :clearResult="isClearResult"  :showDialogEdit="showDialogEdit" :month="month" :data="dataResult"></ResultKraComponent>
              </el-dialog>
            </div>
        </div>
        <el-dialog width="70%" title="Kết quả KRA chi tiết" :visible.sync="dialogDetailResultKip">
          <ResultDetailComponent :is_link="is_link"  :dataDetail="dataView"></ResultDetailComponent>
        </el-dialog>
      </div>
    </div>
  </template>
  
  <script>
  import ResultKraComponent from "../component/ResultKraComponent.vue"
  import ResultDetailComponent from "../component/ResultDetailComponent.vue"
  export default {
      components: {
        ResultKraComponent, ResultDetailComponent
      },
      props: {
        data: {
           type: [Array, Object],
           default: () => []
        },
        month: {
            type: String,
            default: ''
        },

        pageCount: {
            type: Number,
            default: 0
        },
      },

      data() {
          return {
            page: 1,
            last_page: 1,
            textarea: '',
            dataResult: [],
            dataView: '',
            isClearResult: false,
            is_link: false,
            showDialogEdit: false,
            dialogDetailResultKip: false,
            loading: false,
            statusKpiCss: {
                0: "background-color: rgba(255, 0, 0, 1); text-white",
                1: "background-color: rgba(0, 128, 13, 1); text-white",
            },
            statusKpi: {
                0: "Cần nhập",
                1: "Đã nhập",
            },
          }
      },

      methods: {
        dialogEdit(item) {
          this.dataResult = item;
          this.showDialogEdit = true;
          this.isClearResult = true;
        },
       
        dialogView(item) {
        const urlPattern = /(https?:\/\/[^\s]+)/g;
        item.results = item.results.replace(urlPattern, (url) => {
          return `<a href="${url}" class="styled-link">${url}</a>`;
        });
          this.dataView = item;
          this.is_link = this.isLink(item);
          this.dialogDetailResultKip = true;
        },
        isLink(item) {      
          let is_link = item.results ?? "";
          if (!item) {
            this.$notify.error({
              title: "Thất bại",
              message: "Chưa có yêu cầu thành phẩm!",
              type: "error",
            });
            return;
          }
          const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/;
          return urlRegex.test(is_link);
        },

        clickCallback(obj) {
            this.$emit('emit', obj);
        },

        closeDialog(is_reload) {
          if(is_reload) {
            this.$emit('emit')
          }
          this.showDialogEdit = false;
        },
        checkShowButton(item) {
          const result = Object.values(item.kra_manager_weeks).some(function(item) {
            return item.percent_results !== null;
          });
            
          if (result) {
              return true
          }
        } 
      }
  }
  </script>
  
  <style>
    #KpiJobStaffComponent .el-textarea__inner{
      word-break: break-word;
      min-height: 37px !important;
    }
    #KpiJobStaffComponent .link {
      color: rgb(0, 153, 255) !important;
      text-decoration: underline !important;
    }
    #KpiJobStaffComponent .link:hover {
      cursor: pointer;
      color: blue !important;
      text-decoration: underline !important;
    }
 
    #KpiJobStaffComponent .text-color {
      color: rgba(69, 70, 116, 1);
    }
    #KpiJobStaffComponent .input.el-input__inner .bg-sivel {
      background-color: red !important;
    }
    #KpiJobStaffComponent .el-dialog__body{
      padding: 10px 20px;
    }

    #KpiJobStaffComponent .btn-outline-warning {
        color: rgb(246, 148, 0);
        outline: rgb(246, 148, 0);
    }

    #KpiJobStaffComponent .btn-outline-primary {
        color: rgba(0, 26, 114, 1);
        outline: rgba(0, 26, 114, 1);
    }

    .btn-outline-primary:hover {
      color: #ffff;
      background-color:  rgb(92, 201, 232);
      border-color:  rgb(92, 201, 232); 
    }
    

    #KpiJobStaffComponen .btn-outline-warning {
      color: rgba(255, 153, 0, 1);
      border-color: rgba(255, 153, 0, 1); }
      .btn-outline-warning:hover {
        color: #000 !important;
        background-color: rgba(255, 153, 0, 1);
        border-color: rgba(255, 153, 0, 1); 
    }
    .bg-dangerr{
      background-color: rgba(255, 0, 0, 1);
    }

    .bg-successs{
      background-color: rgba(10, 129, 0, 1);
    }
  </style>
  
  
  