
<template>
    <div class="row">
      <div class="w-75">
        <div id="" class="panel-container show ml-2">
            <div class="m-2">
                <div class="ml-3">
                    <b style="color: rgba(18, 0, 219, 1);">Điểm tích họp tuần: {{ data?.total_score_meeting >= 0 && data?.total_score_meeting !== null ? data?.total_score_meeting : "Chưa có" }}</b>
                </div>
                <div>
                    <div class="card-body">
                        <div class="example mb-10">
                          <div class="row filter_form">
                          </div>
                          <div class="example-preview table-responsive">
                            <table class="table table-vertical-center table-bordered" v-loading="loading" element-loading-text="Loading...">
                              <thead>
                                <tr style="background-color: #F8F8F8;">
                                  <th>Tiêu chí</th>
                                  <th v-for="(item, index) in (data?.week_meetings?.[0]?.week || [])" :key="index">
                                    Kết quả tuần {{ index + 1 }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(item, index) in data?.week_meetings" :key="item.id">
                                    <td>{{ item?.criteria_name }}</td>
                                    <td v-for="(result, index) in item?.week">
                                      <div class="rounded p-1" :class="statusMettingCss[item?.week[index][Object.keys(result)[0]]] ?? 'text-dark'"> {{ statusMetting[item?.week[index][Object.keys(result)[0]]] ? statusMetting[item?.week[index][Object.keys(result)[0]]] : "Chưa có" }}</div>
                                </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="example mb-10 mt-2">
                            <div class="d-block">
                              <div class="mb-2">
                                <span>Nhận xét:</span>
                              </div>
                                <div class="border rounded p-2">
                                  <span style="color:#000" :style="!data.description ? 'color:rgb(0 0 0 / 42%)' : ''" class="mt-1" placeholder="Không có nhận xét" >{{ data.description }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
      name: "",
      components: {
      },
      props: {
        data: {
          type: [Array, Object],
          default: () => []
        },
      },
      data() {
          return {
            loading: false,
            statusMettingCss: {
                1: "text-color-kpi-succecc",
                0: "kra-danger",
            },
            statusMetting: {
                1: "Đạt",
                0: "Không đạt",
            },
            score_week: '',
          }
      },
  }
  </script>
  
  <style>
    .text-color-kpi-succecc {
      color: #006B04;
    }

    .kra-danger {
      color: #FF0000;
    }
  </style>
  
  
  